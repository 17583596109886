@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bg-color: #ffffff;
  --text-color: #212121;
  --border-color: #b1b1b1;
  --dash-color: #bebebe;
  --streamalive-background: #f5f5f5;
}

.dark {
  --nextui-background: 0, 0%, 7%;
  --nextui-foreground: 0, 0%, 86%;
  background-color: var(--nextui-background);
  --dash-color: #707070;
  --streamalive-background: #212121;
}

.dark\:bg-default:is(.dark *) {
  background-color: #212121 !important;
}

.pulse {
	background: #FF0064;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(255, 0, 100, 1);
	height: 20px;
	width: 20px;
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 0, 100, 1);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@layer base {
  h1 {
    @apply text-5xl;
    @apply leading-14;
  }

  h2 {
    @apply text-4xl;
    @apply leading-10;
  }

  h3 {
    @apply text-3xl;
    @apply leading-9;
  }

  h4 {
    @apply text-2xl;
    @apply leading-8;
  }

  h5 {
    @apply text-xl;
    @apply leading-5;
  }

  .Body-Large {
    @apply text-xl;
    @apply leading-7;
  }

  .Body-Default {
    @apply text-base;
    @apply leading-6;
  }

  .Body-Small {
    @apply text-sm;
    @apply leading-5;
  }

  .Caption-Small {
    @apply text-sm;
    @apply leading-5;
  }

  .Caption-Extra-Small {
    @apply text-xs;
    @apply leading-4;
  }
}

/* @layer base {
} */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap'); */

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter/Inter-VariableFont_slnt,wght.ttf')
    format('TrueType');
  font-display: swap;
  font-weight: 100 700;
}

html {
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'Inter', sans-serif !important;
  margin: 0 auto;
  background-color: var(--streamalive-background);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  overflow: hidden;
}

:root {
  --toastify-color-info: #438bf7 !important;
  --toastify-color-success: #01b876 !important;
  --toastify-color-warning: #ffa940 !important;
  --toastify-color-error: #d71d10 !important;

  .Toastify__toast-icon {
    align-self: self-start;
  }

  .Toastify__progress-bar {
    opacity: 0.1 !important;
  }

  .Toastify__toast {
    max-width: 100%;
    min-height: 44px;
    border-radius: 6px !important;
    font-size: 0.875rem;
    padding: 8px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .Toastify__toast-body {
    display: flex;
    padding: 8px 4px;
    margin: 0;
    align-items: flex-start;
    justify-items: flex-start;
  }
}

.indicator {
  transition: all 0.3s ease;

  animation: slideIn 0.3s forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    left: -10px;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

.scroll-hidden {
  height: calc(100vh - 357px);
  overflow: auto;
}

.ScrollHide::-webkit-scrollbar {
  display: none;
}

input:checked ~ .dot {
  transform: translateX(100%);
}

input:checked ~ .dot {
  transform: translateX(100%);
  /* background-color: #FF0064; */
}

.border-right {
  border-right: 1px solid #dbdbdb;
}

#teams-container {
  height: calc(100% - 70px);
}

#scrollableDiv1 {
  height: calc(100vh - 220px);
}

#scrollableResultDiv1 {
  height: calc(100vh - 500px);
}

#sessions {
  height: 100vh;
}

@media (max-width: 900px) {
  .border-right {
    border-right: unset;
  }
}

.primary-gradient {
  background: linear-gradient(
    221.91deg,
    #000000 50%,
    rgba(255, 0, 100, 0.53) 157.9%
  );
}

.primary-gradient-hover:hover {
  background: linear-gradient(
    221.91deg,
    #000000 50%,
    rgba(255, 0, 100, 0.53) 157.9%
  );
}

.Slider {
  background: linear-gradient(270deg, #d70404 0%, rgba(215, 4, 4, 0) 50%),
    linear-gradient(90deg, #71c7ec 0%, rgba(113, 199, 236, 0) 56.56%);
}

.SliderCircle {
  border: 1px solid #000000;
  border: 1px solid black;
}

.overflow-auto {
  overflow: auto;
}

.overflow-auto::-webkit-scrollbar {
  display: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: hidden;
  /* Hide scrollbar for IE, Edge add Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Firefox */
}

.no-scrollbar1::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

.no-scrollbar1 {
  /* Firefox */
  overflow-y: scroll;
}

.no-scrollbar2::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

.no-scrollbar2 {
  /* Firefox */
  overflow-x: scroll;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.highcharts-plot-band {
  cursor: pointer;
}

.meetingListWrap {
  height: calc(100vh - 172px);
  overflow-y: auto;
}

.meetingListWrap1 {
  height: calc(100vh - 113px);
  overflow-y: auto;
}

.rosListWrap {
  height: calc(100vh - 205px);
  overflow-y: auto;
}

.rosListWrap1 {
  height: calc(100vh - 273px);
  overflow-y: auto;
}

.modListWrap {
  height: calc(100vh - 322px);
  overflow-y: auto;
}

.homeListWrap {
  height: calc(100vh - 119px);
  overflow-y: auto;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.Slider {
  background: linear-gradient(270deg, #d70404 0%, rgba(215, 4, 4, 0) 50%),
    linear-gradient(90deg, #71c7ec 0%, rgba(113, 199, 236, 0) 56.56%);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ff0064;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ff0064;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.scroll-section {
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
}

.scroll-card {
  scroll-snap-align: start;
}

.drop-down-wrap .drop-down-wrap-inner {
  inset: inherit !important;
  transform: inherit !important;
  top: 100% !important;
  margin-top: 2px !important;
}

.poll-wrapper,
.map-wrapper {
  height: calc(100% - 114px);
}

.inner-poll-wrap {
  height: calc(100% - 52px);
}

.inner-poll-option-wrap {
  height: calc(100% - 116px);
}

.question-polls-height {
  height: calc(100vh - 304px);
}

.question-polls-height > div:nth-last-child(2) {
  margin-bottom: calc(100vh - 46rem);
}

.engagementWrapper-height-polls {
  height: calc(100vh - 105px);
}

.engagementWrapper-height-polls .question-polls-height {
  height: calc(100vh - 208px);
}

.engagementWrapper-height-polls .question-polls-height > div:nth-last-child(2) {
  margin-bottom: calc(100vh - 40rem);
}

.start-stream-wrap {
  height: calc(100% - 107px);
}

.iframe-responsive-container iframe {
  width: 100% !important;
  height: 360px !important;
}

.map-container.mapboxgl-map canvas {
  height: 100% !important;
}

@media (max-width: 1024px) {
  .poll-wrapper,
  .map-wrapper {
    height: calc(100vh - 124px);
  }

  .inner-poll-wrap {
    height: 635px;
  }

  .question-polls-height {
    height: calc(100vh - 334px);
  }

  #scrollableDiv1 {
    height: 100%;
  }
}

@media (max-width: 768px) {
  .question-polls-height > div:nth-last-child(2) {
    margin-bottom: calc(100vh - 59.2rem);
  }

  .engagementWrapper-height-polls {
    height: calc(100vh - 90px);
  }

  .engagementWrapper-height-polls .question-polls-height {
    height: calc(100vh - 204px);
  }

  .engagementWrapper-height-polls
    .question-polls-height
    > div:nth-last-child(2) {
    margin-bottom: calc(100vh - 64rem);
  }
}

@media (max-width: 420px) {
  .iframe-responsive-container iframe {
    height: 200px !important;
  }
}

.active {
  border: 2px solid red;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-attrib-inner {
  display: none;
}

.mapbox-improve-map {
  display: none;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dots {
  display: flex;
  justify-content: center;
}

.dots div {
  width: 12px;
  height: 12px;
  margin: 0 6px;
  border-radius: 50%;
  background-color: #ff0064;
  animation: fade 0.8s ease-in-out alternate infinite;
}

.dots div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.dots div:nth-of-type(2) {
  animation-delay: -0.2s;
}

.icon-animate:hover {
  animation: bounce 1s infinite;
}

@keyframes fade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.loaderMessage {
  animation: fade 2s ease-in-out reverse;
}

.new-loader-dots div {
  width: 8px;
  height: 8px;
  margin: 0 6px;
  border-radius: 50%;
  background-color: #c4c4c4;
}

.dots-xl div {
  width: 12px;
  height: 12px;
  margin: 0 13px;
}

.new-loader-dots-small div {
  width: 8px;
  height: 8px;
  margin: 0 3px;
  aspect-ratio: 8/5;
  border-radius: 50%;
  background-color: #c4c4c4;
}

.lightup-default div {
  animation: lightup-red 1.5s ease-in-out reverse infinite;
}

.lightup-primary div {
  background-color: #312c2c;
  animation: lightup-white 1.5s ease-in-out reverse infinite;
}

.lightup-black div {
  background-color: #312c2c;
  animation: lightup-white 1.5s ease-in-out reverse infinite;
}

.lightup-secondary div {
  background-color: #f5a5a5;
  animation: lightup-secondary 1.5s ease-in-out reverse infinite;
}

.new-loader-dots div:nth-of-type(2) {
  animation-delay: 0.25s;
}

.new-loader-dots div:nth-of-type(3) {
  animation-delay: 0.5s;
}

.new-loader-dots div:nth-of-type(4) {
  animation-delay: 0.75s;
}

.new-loader-dots-small div:nth-of-type(2) {
  animation-delay: 0.33s;
}

.new-loader-dots-small div:nth-of-type(3) {
  animation-delay: 0.66s;
}

@keyframes lightup-red {
  to {
    background-color: #ff0064;
  }
}

@keyframes lightup-white {
  to {
    background-color: #f2f2f2;
  }
}

@keyframes lightup-secondary {
  to {
    background-color: #ffffff;
  }
}

.box-gradient-h {
  background: linear-gradient(
    221.91deg,
    #000000 50%,
    rgba(255, 0, 100, 0.53) 157.9%
  );
  border: 1px solid #ff0064;
  box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15);
}

.circle-point {
  background: #ff0064;
}

/* stream.js tooltip */
.streamtooltip .streamtooltiptext::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: #333333 transparent transparent transparent;
}

.streamtooltip1 {
  background: rgba(34, 34, 34, 0.8);
  border-color: #333333;
}

/* poll-WonderWords tooltip */
.polltooltip .polltooltiptext::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: #e5f5fc transparent transparent transparent;
}

.polltooltip1 .polltooltiptext1::after {
  content: ' ';
  position: absolute;
  bottom: 100%;
  right: 50%;
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: #e5f5fc transparent transparent transparent;
}

.primaryActiveBtn:active {
  transform: scale(0.98);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.24);
}

.secondaryActiveBtn:active {
  transform: scale(0.98);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.24);
}

.ghostActiveBtn:active {
  transform: scale(0.98);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.24);
}

.backActiveBtn:active {
  transform: scale(0.88);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.24);
}

.arrowActiveBtn:active {
  transform: scale(0.88);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.24);
}

.moreButton::before {
  content: '';
  height: 6px;
  width: 6px;
  background-color: #a7a7a7;
  border-radius: 50%;
  display: inline-block;
  margin: 15px;
  position: absolute;
  left: -15px;
  top: -26px;
}

.moreButton::before:hover {
  background-color: '#FF0064';
}

.rounded8:hover {
  border-radius: 4px;
}

.moreButton::after {
  content: '';
  height: 6px;
  width: 6px;
  background-color: #a7a7a7;
  border-radius: 50%;
  display: inline-block;
  margin: 15px;
  margin-top: 8px;
  position: absolute;
  left: -15px;
  top: 3px;
}

.moreButton::after:hover {
  background-color: '#FF0064';
}

.moreButton {
  height: 6px;
  width: 6px;
  background-color: #a7a7a7;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.flexdiv {
  width: 48%;
}

.reqAccessList {
  height: calc(100vh - 370px);
  overflow-y: auto;
}

.dropzone {
  text-align: center;
  padding: 5px;
  border: 1px red dashed;
  background-color: #f5f5f5;
  max-width: 244px;
  height: 136px;
}

.slide-backgroud {
  background: linear-gradient(
    270deg,
    #ccf0ff 0%,
    rgba(255, 255, 255, 0.9) 97.36%
  );
}

.hidden-div {
  height: 0;
  overflow: hidden;
}

.footerWidth {
  min-width: -webkit-fill-available;
}

.interactions-list {
  max-height: calc(100vh - 440px);
  min-height: 100px;
}

.scroll-shadow {
  box-shadow: 0px -10px 10px #9c9caf;
}

.dropDownBg {
  background-color: rgba(34, 34, 34, 0.8);
}

.stream-colors-height {
  height: calc(100vh - 120px);
}

.w-fit {
  width: fit-content;
}

.glass-morphic-color {
  background-color: rgb(255, 255, 255, 0.1);
}

.comment-overlay-blur {
  background: rgba(34, 34, 34, 0.8);
}

.layoutWidth {
  width: 97%;
}

.triangle {
  clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
}

.-skew-x-49 {
  transform: skewX(-49deg);
}

.tooltip-border {
  width: calc(50% - 7px);
}

.poll-borderLeft {
  width: calc(50% - 10px);
}

#choice-circle-div {
  /* background: url('/images/InteractionPage/bg-dots.svg'); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
}

.poll-borderRight {
  width: calc(50% - 4px);
}

.TM-border-right {
  width: calc(50% - 31px);
}

.TM-border-left {
  width: calc(50% - (-17px));
}

.loader-dots {
  width: 25px;
  aspect-ratio: 8/5;
  --_g: no-repeat radial-gradient(#000 68%, #0000 71%);
  -webkit-mask: var(--_g), var(--_g), var(--_g);
  -webkit-mask-size: 25% 40%;
  background: rgb(0, 0, 0);
  background-size: 200% 50%;
  animation:
    back 3s infinite steps(1),
    load 1.5s infinite linear;
}

.poll-background {
  background: linear-gradient(
    270deg,
    rgba(249, 123, 95, 0.6) 0%,
    rgba(249, 123, 95, 0) 100%
  );
}

@keyframes load {
  0% {
    -webkit-mask-position:
      0% 0%,
      50% 0%,
      100% 0%;
  }

  12.5% {
    -webkit-mask-position:
      0% 50%,
      50% 0%,
      100% 0%;
  }

  25% {
    -webkit-mask-position:
      0% 100%,
      50% 50%,
      100% 0%;
  }

  37.5% {
    -webkit-mask-position:
      0% 100%,
      50% 100%,
      100% 50%;
  }

  50% {
    -webkit-mask-position:
      0% 100%,
      50% 100%,
      100% 100%;
  }

  62.5% {
    -webkit-mask-position:
      0% 50%,
      50% 100%,
      100% 100%;
  }

  75% {
    -webkit-mask-position:
      0% 0%,
      50% 50%,
      100% 100%;
  }

  87.5% {
    -webkit-mask-position:
      0% 0%,
      50% 0%,
      100% 50%;
  }

  100% {
    -webkit-mask-position:
      0% 0%,
      50% 0%,
      100% 0%;
  }
}

@keyframes back {
  0%,
  100% {
    background-position:
      0% 0%,
      0% 100%;
  }

  25% {
    background-position:
      100% 0%,
      0% 100%;
  }

  50% {
    background-position:
      100% 0%,
      100% 100%;
  }

  75% {
    background-position:
      0% 0%,
      100% 100%;
  }
}

@keyframes fade-out {
  from {
    transform: scale(0.5);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.animation {
  animation-name: fade-out;
  animation-duration: 1s;
}

.h-fit {
  height: fit-content;
}

/* SA dropdown main nav */
.sa-dropdown-item {
}

.sa-dropdown-item:not(:first-child) {
}

/* SA Table */
.sa-table {
  width: 100%;
}

.sa-table th {
}

.sa-table > tbody td {
  /* border: 1px solid gray; */
  /* font-weight: 600; */
}

.sa-table td {
  padding: 24px 16px;
}

.sa-table > thead > tr {
  background-color: none;
}

.sa-table > tbody > tr:nth-child(odd) {
  background-color: #ffffff;
}

.sa-table > tbody > tr:nth-child(even) {
  background-color: #f9f9fb;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.border-gray {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.sa-nav-inner-pill {
  background: linear-gradient(
    221.91deg,
    #000000 50%,
    rgba(255, 0, 100, 0.53) 157.9%
  );
  border-radius: 4px;
  width: 54px;
  height: 16px;
  padding: 4px;
}

.checkbox1:checked + span img {
  display: block;
}

.interaction-enter {
  opacity: 0;
}

.interaction-enter-active {
  opacity: 1;
  transition:
    opacity 500ms,
    transform 500ms;
}

.interaction-exit {
  opacity: 1;
}

.interaction-exit-active {
  opacity: 0;
  transition:
    opacity 500ms,
    transform 500ms;
}

.arrow-down {
  background-color: white;
  -webkit-mask: url('/images/newUpArrow.svg') no-repeat center;
  mask: url('/images/newUpArrow.svg') no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.border-focused:focus-visible {
  outline: none !important;
  border: 2px solid #d1d1d1 !important;
}

.linear-PricingPlans {
  background: linear-gradient(
    171deg,
    rgb(238, 133, 194) 0%,
    rgba(217, 255, 0, 0.42200630252100846) 44%,
    rgb(168, 252, 255) 100%
  );
  /* border-top: 2px solid rgb(245, 15, 15);
  border-left: 2px solid rgb(218, 4, 4);
  border-bottom: 2px solid rgb(26, 228, 26);
  border-right: 2px solid rgb(41, 238, 41); */
}

/* Hide the default scrollbar */
/* ::-webkit-scrollbar {
  height: .25rem;
  width: .4rem
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: 'dark:#444444 #f1f1f1';
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: .25rem;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #909090;
} */

.scrollable-container {
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
  overflow-y: scroll;
  /* Enable vertical scrollbar */
  cursor: pointer;
}

.menu_animation {
  position: absolute;
  left: -36px;
  top: 16px;
  width: 80px;
  height: 92%;
  transition: left 0.3s ease-out;
  padding: 1px;
  border: none;
}

.menu_animation.active {
  left: 15px;
}

.bottomMenu_animation {
  position: absolute;
  left: 80px;
  right: 12px;
  bottom: -44px;
  width: full;
  height: 60px;
  transition: bottom 0.3s ease-out;
  padding: auto;
  border: none;
}

.bottomMenu_animation.active {
  bottom: 12px;
}

@font-face {
  font-family: 'Flama';
  src: url('../public/fonts/flama/Flama Bold Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../public/fonts/helvetica-nueue/Helvetica Neue Medium Extended.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}

.container .next-tooltip {
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  background-color: #000000;
  padding: 8px 12px;
}

.container .preview-tooltip,
.container .edit-tooltip,
.container .delete-tooltip,
.container .duplicate-tooltip {
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  background-color: #000000;
  padding: 8px 12px;
}

input {
  outline: none;
}

.container .interaction-title-tooltip {
  width: 100%;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background-color: #000000;
  color: #fff;
  text-align: center;
  z-index: 9999;
}

.react-datepicker {
  font-family: inherit !important;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink 1s linear infinite;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 200px 0;
  }
}

.striped-background {
  @apply bg-pink-200 bg-repeat;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 20px 20px;
  animation: move 3s linear infinite;
}

.popper-tooltip::after {
  content: '';
  position: absolute;
  border-style: solid;
}
.popper-tooltip[data-popper-placement^='top']::after {
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0 5px;
}

.popper-tooltip[data-popper-placement^='top-end']::after {
  bottom: -5px;
  left: 90%;
  margin-left: -5px;
  border-width: 5px 5px 0 5px;
}

.popper-tooltip[data-popper-placement^='top'][data-variant='warning']::after,
.popper-tooltip[data-popper-placement^='top-end'][data-variant='warning']::after {
  border-color: #ffa940 transparent transparent transparent;
}

.popper-tooltip[data-popper-placement^='top'][data-variant='black']::after,
.popper-tooltip[data-popper-placement^='top-end'][data-variant='black']::after {
  border-color: #000000 transparent transparent transparent;
}

.popper-tooltip[data-popper-placement^='top'][data-variant='dark']::after,
.popper-tooltip[data-popper-placement^='top-end'][data-variant='dark']::after {
  border-color: #424242 transparent transparent transparent;
}

.popper-tooltip[data-popper-placement^='top'][data-variant='white']::after,
.popper-tooltip[data-popper-placement^='top-end'][data-variant='white']::after {
  border-color: #ffffff transparent transparent transparent;
}

.chroma{
  background-color: #00B140 !important;
}

.login-gradient{
  background: linear-gradient(147deg, #B50047 0%, #000 25.68%, #000 74.81%, #3D7EE1 99.22%);
}

#workspace-section span[data-slot='heading'] {
  color: #909090;
  font-weight: 500;
  font-size: 12px;
  margin-left: 12px;

}

tr:empty {
  display: none;
}

@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@-moz-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-30px);
  }
  60% {
    transform: translateX(-15px);
  }
}

@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
/* /right bounce */

/* assign bounce */
.animaiton-arrow-right {
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite;
  float:right;
}

@keyframes shake {
  0%, 100% { transform: translate(0, 0); }
  10%, 30%, 50%, 70%, 90% { transform: translate(-1px, 0); }
  20%, 40%, 60%, 80% { transform: translate(1px, 0); }
}

/* assign shake */
.animaiton-shake {
  animation: shake 2.5s cubic-bezier(.36,.07,.19,.97) infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.animate-blink {
  animation: blink 1s infinite;
}

.upcoming-sessions{
  position: relative;
  z-index: 0;
}

.upcoming-sessions::before{
  content: '';
  background: url('/images/landingPageUpcommingSessions.png') no-repeat right center/contain;
  position: absolute;
  top: 0;
  left: 20%;
  width: 80%;
  height: 100%;
  opacity: 0.45; /* Adjust opacity as needed */
  z-index: 40; /* Ensure it stays behind the content */
}

.slider-border-width {
  border-left-width: 0.5rem !important;
  border-right-width: 0.5rem !important;
}


/* cello notification badge size */

.cello-sb8noz{
  width: 8px !important;
  height: 8px !important;
  left: 94% !important;
  top: 4% !important;
}
/* wont work if any styles changed in cello dashboard default for notification badge is 0 all */
.cello-29ecmz{
  width:8px !important;
  height:8px !important;
}

.processing-bar {
  width: 300px;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.processing {
  height: 100%;
  background-color: #ff007f;
  width: 50%;
  position: absolute;
  top: 0;
  left: -50%;
  animation: move 2s linear infinite;
  border-radius: 4px;
}

@keyframes move {
  0% {
    left: 0;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 0;
  }
}



.mulitpleChoiceOptionText{
  font-size: calc(0.875rem + 0.5vw);
  line-height: calc(1.25rem + 0.5vw); ;

}

.animate-spinner-ease-spin{
  margin: 0  !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 2147483647;
  pointer-events: all;
}

.primary-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 51.5%, rgba(255, 0, 100, 0.19) 100%);
}
.animated-path-polls {
  transform-origin: left;
  animation: scaleXAnimation 1s ease-out forwards;
}

@keyframes scaleXAnimation {
  from {
    transform: scaleX(.6);
    transform-origin: left;
  }
  to {
    transform: scaleX(1);
    transform-origin: left;
  }
}

/* Disable dark mode for react-best-gradient-color-picker */
.react-best-gradient-color-picker {
  background-color: #fff !important;  /* Light background */
}

.react-best-gradient-color-picker .gradient-picker__slider {
  background-color: #f0f0f0 !important;  /* Light slider */
}

.react-best-gradient-color-picker .gradient-picker__handle {
  background-color: #ddd !important;  /* Light handle color */
}

.react-best-gradient-color-picker .gradient-picker__selected {
  background-color: #ffcc00 !important;  /* Set a default selected color */
}

#rbgcp-inputs-wrap div div {
  color: #707070 !important;
}
#rbgcp-inputs-wrap + div div:first-child {
  border: 1px solid #DBDBDB !important;
}

.override-font-18 * {
  font-size: 18px !important;
}

.truncate-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  line-clamp: 2; /* Standard property */
  overflow: hidden;

  display: -moz-box;
  display: box;
  -moz-box-orient: vertical;
  box-orient: vertical;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}
